import logo from './logo.svg';
import './App.css';

import { get } from '@aws-amplify/api-rest';
import awsconfig from './aws-exports';
import React, { useEffect, useState } from 'react'
import AddStock from './AddStock';
import { Outlet, Link } from "react-router-dom";
import { useNavigate, Navigate } from 'react-router-dom';
import Settings from './Settings';
import { Button } from '@aws-amplify/ui-react';
import { useActiveButton } from './ActiveButtonContext';

const myAPI = "api30769188"
const path = '/customers'; 

const MainMenuLongTerm = ({signOut}) => {
  const [input, setInput] = useState("");
  const [customers, setCustomers] = useState([]);
  //const [activeButton, setActiveButton] = useState('inTheMoney');
  const { activeButton, setActiveButton } = useActiveButton();

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };


  const navigate = useNavigate();

  useEffect(()=>{
    navigate(input);
  },[input, activeButton])

//changed titles
  //Function to fetch from our backend and update customers array
  function getCustomer(e) {
    let customerId = e.input
    get(myAPI, path + "/" + customerId)
       .then(response => {
         console.log(response)
         let newCustomers = [...customers]
         newCustomers.push(response)
         setCustomers(newCustomers)

       })
       .catch(error => {
         console.log(error)
       })
  }

  return (
    
<div className='MenuStyle'>
<h1 style={{ display: 'none' }}>Main Menu Long Term</h1>
  <div className="menu-row">
    
  </div>
  <div className="menu-row">
    <label style={{ width: '200%', marginTop: "1%", marginBottom:"1%", backgroundColor: "lightgray", fontWeight: "bold"}} onClick={()=>{
    }}>
      ETFs
    </label>
  </div>
  <div className="menu-row">
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/list"); 
    }}>
      List
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runAllLongTerm"); 
    }}>
      Analysis
    </Button>
  </div>
  <div className="menu-row">
    <label style={{ width: '200%', marginTop: "1%", marginBottom:"1%", backgroundColor: "lightgray", fontWeight: "bold"}} onClick={()=>{
    }}>
      Securities
    </label>
  </div>
  

  <div className="menu-row">
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runGrowthTechnologyLongTerm"); 
      }}
      title="Technology stocks with revenue and earnings growth in excess of 25%"
      >
        Growth Technology
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runMostActiveLongTerm"); 
      }}
      title="Stocks ordered in descending order by intraday trade volume"
      >
        Most Active
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runDayGainersLongTerm"); 
      }}
      title="Stocks ordered in descending order by price percent change with respect to the previous close"
      >
        Most Gainers
    </Button>
  </div>

  <div className="menu-row">
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runDayLosersLongTerm"); 
      }}
      title="Stocks ordered in ascending order by price percent change with respect to the previous close"
      >
        Most Losers
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runAggressiveLongTerm"); 
      }}
      title="Small cap stocks with earnings growth rates better than 25%"
      >
        Small Caps Aggressive
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runSmallLongTerm"); 
      }}
      title="Small Caps with a 1 day price change of 5.0% or more"
      >
        Small Caps Gainers
    </Button>
  </div>

  <div className="menu-row">
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runUndervaluedLongTerm"); 
      }}
      title="Large cap stocks that are potentially undervalued"
      >
        Undervalued Large Caps
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runUnderValuedGrowthLongTerm"); 
      }}
      title="Stocks with earnings growth rates better than 25% and relatively low PE and PEG ratios"
      >
        Undervalued Growth
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/run52wk_toppicksLongTerm"); 
      }}
      title="Stocks achieved a new 52-Week High in the current session with over 10% price change over the past month"
      >
        52wk Top Picks
    </Button>
  </div>

  <div className="menu-row">
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runhigh_volumeLongTerm"); 
      }}
      title="Stocks with strong volume gains from the previous session and positive price and volume momentum in the past month"
      >
        High Volume
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runhot_stocksLongTerm"); 
      }}
      title="High-performing stocks with a BUY rating and robust technical analysis support, indicating a strong and potential upward trend"
      >
        Hot Stocks
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runtop_under_10LongTerm"); 
      }}
      title="Stocks priced under $10 with strong Price/Earnings and Price/Sales ratios"
      >
        Top Under 10
    </Button>
  </div>

  <div className="menu-row">
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/rundividendLongTerm"); 
      }}
      title="Dividend-paying stocks showing bullish signals and exhibiting strong momentum"
      >
        Dividend
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runtop_fundamentalsLongTerm"); 
      }}
      title="Stocks demonstrating robust market fundamentals, featuring strong sales growth, increased cash flow, and higher shareholder equity"
      >
        Top Fundamentals
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runtop_techLongTerm"); 
      }}
      title="Screen for computer and tech sector stocks hitting new 6-month highs, indicating a strong buying opportunity"
      >
        Top Tech
    </Button>
  </div>

  <div className="menu-row">
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runj_patternLongTerm"); 
      }}
      title="Stocks with J-Hook pattern - up-trending price action that takes a little dip before resuming the trend"
      >
        J Pattern
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/rungolden_crossLongTerm"); 
      }}
      title="Stocks experiencing a bullish signal as their 50-day moving average crosses above the 200-day moving average today"
      >
        Golden Cross
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/rundeath_crossLongTerm"); 
      }}
      title="Stocks experiencing a bearish signal as their 50-day moving average crosses below the 200-day moving average today"
      >
        Death Cross
    </Button>
  </div>

  <div className="menu-row">
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runconsolidationLongTerm"); 
      }}
      title="Stocks in a strong uptrend consolidating near highs, aiming to spot sideways price congestion within the trend"
      >
        Consolidation
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runrsi_overboughtLongTerm"); 
      }}
      title="High-volume stocks with a 20-Day RSI above 70, signaling potential overbought conditions and suggesting a possible trend reversal"
      >
        RSI Overbought
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runrsi_oversoldLongTerm"); 
      }}
      title="High-volume stocks with a 20-Day RSI below 30, signaling potential oversold conditions and suggesting a possible trend reversal to the upside"
      >
        RSI Oversold
    </Button>
  </div>

  <div className="menu-row">
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runpenny_gapLongTerm"); 
      }}
      title="Penny stocks with the highest percentage change and price movements over the last 5 days"
      >
        Penny Gap
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/rundefensive_stockLongTerm"); 
      }}
      title="Stocks offering reliable dividends and sustained positive sales growth irrespective of market conditions"
      >
        Defensive Stock
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runincome_growthLongTerm"); 
      }}
      title="Stocks with high dividends and earnings growth, coupled with solid dividend yields"
      >
        Income Growth
    </Button>
  </div>

  <div className="menu-row">
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runbuy_longtermLongTerm"); 
      }}
      title="Bullish stocks maintaining a 100% Overall Buy Signal"
      >
        Buy Longterm
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runmoversUpLongTerm"); 
      }}
      title="Market movers up in today's trading session"
      >
        Movers Up
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/runmoversDownLongTerm"); 
      }}
      title="Market movers down in today's trading session"
      >
        Movers Down
    </Button>
  </div>
  <div className="menu-row">
    
    <Button backgroundColor="lightgreen" style={{ width: '100%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runOneLongTerm"); 
    }}>
      Custom List
    </Button>
  </div>
  <div className="menu-row">
 
    <Button title="Settings" style={{ width: '50%', marginTop: "1%", marginBottom:"1%"}} onClick={()=>{
      setInput("/editSecurityMenu"); 
    }}>
      <img src="gear.png" alt="Gear Icon" title="Settings" style={{ marginRight: '5px', width: '33%' }} />
    </Button>
    
    
    <Button title="Sign Out" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={signOut}>
      <img src="exit.png" alt="Exit Icon" title="Sign Out" style={{ marginRight: '5px', width: '33%' }} />
    </Button>

  </div>
    
</div>
  )
}

export default MainMenuLongTerm;