import logo from './logo.svg';
import './App.css';
import awsconfig from './aws-exports';
import React, { useEffect, useState, useContext, useRef } from 'react';
import * as Auth from '@aws-amplify/auth';
import { get } from '@aws-amplify/api-rest';
import AddStock from './AddStock';
import { Outlet, Link } from "react-router-dom";
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import Settings from './Settings';
import { Button } from '@aws-amplify/ui-react';
import { useActiveButton } from './ActiveButtonContext';
import {ScreenSizeContext} from './App'
import { strategyLongTerm } from "./lib/common";
import { downloadFile } from './lib/common';

const CompareLongTerm = () => {
  const myAPI="mktappapi";
  const location = useLocation();
  let errorState = useRef(false);
  let [displayMessage, setDisplayMessage] = useState("");
  let data = useRef();
  let email = useRef();
  let oneStock={"stocks":[]};
  let securitiesData = [];

  // Parse the query parameter
  const queryParams = new URLSearchParams(location.search);
  const securitiesString = queryParams.get("securities");

  console.log("Securities String received:", securitiesString);

  // Split the string into an array if needed
  const securitiesArray = securitiesString
  ? securitiesString.split(",").filter(security => security.trim() !== "")
  : [];

  useEffect(() => {
    getUserInfo()
    .then(async()=>{
      await console.log("useEffect : email = " + email.current);
      data.current = await JSON.parse(await downloadFile(email.current));
      console.log("useEffect : data.current = ", data.current);
    })
    .then(async()=>{
      await setDisplayMessage("Getting market data for the symbol(s)");
      await getSecuritiesInfo(securitiesArray)
      .then(async (resp) => {
        //strategyLongTerm : params = {"days":"13","daysOffset":"30","minDepth":"1","excludeLeveragedEtfs":"NO","stocks":"TSLA"}
        let securitiesStr = securitiesArray.toString();
        securitiesStr = securitiesStr.replace(/, /g, ",");
        oneStock["stocks"] = securitiesStr;
        let params={...data.current, ...oneStock};
        let paramsStr=JSON.stringify(params);
        Promise.resolve(await strategyLongTerm(paramsStr))
          .then(async (strategyAnalysis) => {
            await console.log("useeffect : strategyAnalysis = ", strategyAnalysis);
            await setDisplayMessage("");
          })
      })
      .then(async()=>{
        await compareLongTerm(securitiesArray);
      })
      .catch((error) => {
        console.log("useEffect : getSecuritiesInfo : error = ");
        console.log(error);
      });
    })
  }, []);

  async function getSecuritiesInfo(securities) {
    await console.log("useEffect : getSecuritiesInfo : securities = ");
    await console.log(securities);
    
      var param = {"stocks" : securities}
      await console.log("getSecuritiesInfo : response in param = " + JSON.stringify(param));
      var path = '/getSecuritiesInfo';
      var passApiStr=path + "/" + JSON.stringify(param);
      await console.log ("getSecuritiesInfo : passApistr = " + passApiStr);
      passApiStr = await encodeData(passApiStr);
      await console.log ("getSecuritiesInfo : passApistr encoded = " + passApiStr);
      //await get(myAPI, passApistr)
      const response = await get({
        apiName: myAPI,
        path: passApiStr,
      }).response;
      let resp = await response.body.json();
      await Promise.resolve(resp)
      .catch(error =>{
        console.log(error.message);
        errorState.current = true;
        setDisplayMessage(error.message);
      })   
  }

  async function compareLongTerm(securities) {
    await console.log("useEffect : compareLongTerm : securities = ");
    await console.log(securities);
    
      var param = {"stocks" : securities}
      var path = '/compareLongTerm';
      var passApiStr=path + "/" + JSON.stringify(securities);
      await console.log ("compareLongTerm : passApistr = " + passApiStr);
      passApiStr = await encodeData(passApiStr);
      await console.log ("compareLongTerm : passApistr encoded = " + passApiStr);
      //await get(myAPI, passApistr)
      const response = await get({
        apiName: myAPI,
        path: passApiStr,
      }).response;
      let resp = await response.body.json();
      await Promise.resolve(resp)
      .catch(error =>{
        console.log(error.message);
        errorState.current = true;
        setDisplayMessage(error.message);
      })   
  }

  function encodeData(txt) {
    txt = txt.replace(/\{/g,"%7B");
    txt = txt.replace(/\}/g,"%7D");
    txt = txt.replace(/\[/g,"%5B");
    txt = txt.replace(/\]/g,"%5D");
    txt = txt.replace(/\:/g,"%3A");
    txt = txt.replace(/ /g,"%20");
    txt = txt.replace(/\,/g,"%2C");
    txt = txt.replace(/\"/g,"%22");

    return(txt);
  }

  const getUserInfo = async () => {
    try {
      await console.log("Executing getUserInfo");
      await Auth.fetchAuthSession();
      // Retrieve the user's email address
      const user = await Auth.fetchUserAttributes();
      console.log("getUserInfo : email = " + JSON.stringify(user.email));
      email.current = await user.email;
      return "done";
    } catch (error) {
      console.log("Error in getUserInfo: ", error);
      return "error";
    }
  };

  return (
    <div>
      <div className="blink_me">{displayMessage}</div>
      <h1>Compare Long Term</h1>
      <p>Securities: {securitiesString}</p>
      <ul>
        {securitiesArray.map((security, index) => (
          <li key={index}>{security}</li>
        ))}
      </ul>
    </div>
  );
};

export default CompareLongTerm;