import React, { useState, useEffect, useRef } from "react";
import { Button } from "@aws-amplify/ui-react";
import * as Auth from '@aws-amplify/auth';
import { printConsole } from './lib/utility';
import { downloadFile, uploadFile } from "./lib/common";
import './App.css';

const Settings = ({ signOut }) => {
  let email = useRef("");
  const [days, setDays] = useState("");
  const [daysOffset, setDaysOffset] = useState("");
  const [minDepth, setMinDepth] = useState("");
  const [rank, setRank] = useState("ALL");
  const [strikeMin, setStrikeMin] = useState("");
  const [strikeMax, setStrikeMax] = useState("");
  const [profitSort, setProfitSort] = useState("");
  const [excludeLeveragedEtfs, setExcludeLeveragedEtfs] = useState("");
  const [data, setData] = useState({"days": "", "daysOffset": "", "minDepth": "", "excludeLeveragedEtfs": "", "rank":"ALL", "strikeMin": "", "strikeMax":"", "profitSort":""});
  const [error, setError] = useState('');
  const [hide, setHide] = useState("hidden");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  let mydata = useRef();
  let uploadResult = useRef("");

  useEffect(() => {
    async function fetchData() {
      await executeSettingsForm();
      setLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (submitted) {
      let settingsJson = data;
      printConsole("useeffect : settingsJson = " + JSON.stringify(settingsJson));

      if (settingsJson.days === "") {
        settingsJson.days = mydata.current.days.toString();
      }
      if (settingsJson.daysOffset === "") {
        settingsJson.daysOffset = mydata.current.daysOffset.toString();
      }
      if (settingsJson.minDepth === "") {
        settingsJson.minDepth = mydata.current.minDepth.toString();
      }
      if (settingsJson.excludeLeveragedEtfs === "") {
        settingsJson.excludeLeveragedEtfs = mydata.current.excludeLeveragedEtfs.toString();
      }
      if (settingsJson.rank === "ALL") {
        settingsJson.rank = mydata.current.rank.toString();
      }
      if (settingsJson.strikeMin === "") {
        settingsJson.strikeMin = mydata.current.strikeMin.toString();
      }
      if (settingsJson.strikeMax === "") {
        settingsJson.strikeMax = mydata.current.strikeMax.toString();
      }
      if (settingsJson.profitSort === "") {
        settingsJson.profitSort = mydata.current.profitSort.toString();
      }
      printConsole("useeffect : newSettingsStr = " + JSON.stringify(settingsJson));

      //uploadResult.current = Promise.resolve(uploadFile(email.current, settingsJson));

      (async () => {
        await uploadFile(email.current, settingsJson)
        .then(result => {
          uploadResult.current = result;
          printConsole("useeffect : uploadResult = " + uploadResult.current);
          if (uploadResult.current === "completed the upload") {
            uploadResult.current = "Successful Upload";
          } else {
            uploadResult.current = "Upload Failed";
          }
          printConsole("useeffect : uploadResult = " + uploadResult.current);
          setSubmitted(false);
        });
      })();

      
    }
  }, [submitted, data, rank, profitSort, strikeMax, strikeMin]);

  const getUserInfo = async () => {
    try {
      await Auth.fetchAuthSession();
      // Retrieve the user's email address
      const user = await Auth.fetchUserAttributes();
      printConsole("getUserInfo : email = " + JSON.stringify(user.email));
      email.current = await user.email;
      return "done";
    } catch (error) {
      printConsole("Error in getUserInfo: ", error);
      return "error";
    }
  };

  const listFiles = async () => {
    try {
      await printConsole("Executing listFiles");
      if (typeof(email.current) === 'undefined') {
        await getUserInfo();
      }
      await downloadFile(email.current)
      .then(async result => {
        mydata.current = await JSON.parse(result);
        await printConsole("listFiles : downloadFile : mydata = " +JSON.stringify(mydata.current));
      });
      await printConsole("listFiles : mydata = " +JSON.stringify(mydata.current));
      await setData(mydata.current);
      setDays(mydata.current.days);
      setDaysOffset(mydata.current.daysOffset);
      setMinDepth(mydata.current.minDepth);
      setExcludeLeveragedEtfs(mydata.current.excludeLeveragedEtfs);
      setRank(mydata.current.rank);
      setStrikeMin(mydata.current.strikeMin);
      setStrikeMax(mydata.current.strikeMax);
      setProfitSort(mydata.current.profitSort);
    } catch (error) {
      printConsole("Error in listFiles: ", error);
    }
  };

  const executeSettingsForm = async () => {
    await Promise.resolve(await getUserInfo())
    .then(async () => {
      await printConsole ("email = " + email.current);
      if (typeof(email.current) === 'undefined') {
        await getUserInfo();
      }
      await listFiles();
      await printConsole("executeSettingsForm : data.days = " + data.days);
    });
  };

  const handleSubmit = () => {
    uploadResult.current = "Uploading";

    console.log("handleSubmit : rank = ", rank);
    console.log("handleSubmit : strikeMin = ", strikeMin);
    console.log("handleSubmit : strikeMax = ", strikeMax);
    console.log("handleSubmit : profitSort = ", profitSort);

  
    // Construct settings JSON from the current state
    let settingsJson = {
      days: days,
      daysOffset: daysOffset,
      minDepth: minDepth,
      excludeLeveragedEtfs: excludeLeveragedEtfs,
      rank: rank,
      strikeMin: strikeMin,
      strikeMax: strikeMax,
      profitSort: profitSort
    };
  
    console.log("handleSubmit : settingsJson = ", settingsJson);
  
    // Update the data state and mark form as submitted
    setData(settingsJson);
    console.log("handleSubmit : data = ", data);
    mydata.current = settingsJson;
    console.log("handleSubmit : mydata = ", mydata);
    setSubmitted(true);
  };

  const defaultValues = () => {
    setSubmitted(false);
    setDays(30);
    setDaysOffset(10);
    setMinDepth(10);
    setExcludeLeveragedEtfs("NO");
    setRank("ALL");
    setStrikeMin("");
    setStrikeMax("");
    setProfitSort("");
    setData({"days": 30, "daysOffset": 10, "minDepth": 10, "excludeLeveragedEtfs": "NO", "rank":"ALL", "strikeMin":"", "strikeMax":"", "profitSort":""});
  };

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh'
  };
  const inputStyle = {
    margin: '10px',
    padding: '10px',
    width: '300px',
    borderRadius: '5px',
    border: '1px solid #ddd'
  };

  const buttonStyle = {
      margin: '10px',
      padding: '10px 20px',
      borderRadius: '5px',
      border: 'none',
      backgroundColor: '#4CAF50',
      color: 'white',
      cursor: 'pointer'
  };

  /*if (loading) {
    return <div className="blink_me">Loading...</div>;
  }*/

  return (
    <div className="MenuStyle">
      {loading ? (
        <div className="blink_me">Loading...</div>
      ) : (
        <>
          {uploadResult.current === "Uploading" ? (<div className="blink_me">{uploadResult.current}</div>) : (<div className="blink_me"></div>)}
          {uploadResult.current === "Successful Upload" ? (<div className="blink_me">{uploadResult.current}</div>) : (<div className="blink_me"></div>)}
          {uploadResult.current === "Upload Failed" ? (<div className="blink_me_red">{uploadResult.current}</div>) : (<div className="blink_me"></div>)}
          {printConsole("form : data.days = " + data.days + "\tdata.daysOffset = " + data.daysOffset + "\tdata.minDepth = " + data.minDepth + "\tdata.excludeLeveragedEtfs = " + data.excludeLeveragedEtfs + 
            "\tdata.rank = " + data.rank + "\tdata.strikeMin = " + data.strikeMin + "\tdata.strikeMax = " + data.strikeMax + "\tdata.profitSort = " + data.profitSort)}
          {printConsole("form : days = " + days + "\tdaysOffset = " + daysOffset + "\tminDepth = " + minDepth + "\texcludeLeveragedEtfs = " + excludeLeveragedEtfs + 
            "\trank = " + rank + "\tstrikeMin = " + strikeMin + "\tstrikeMax = " + strikeMax + "\tprofitSort = " + profitSort)}

          <form id="settingsForm" name="settingsForm" style={formStyle}>
            {/* Data Options Header */}

            <div style={{ backgroundColor: "blue", color: "white", padding: "10px", borderRadius: "5px", marginBottom: "10px", marginTop: "100px" }}>
              <strong>Data Options</strong>
            </div>
            <br />

            {/* Days and Days Offset */}
            <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
              <label style={{ flex: "1" }}>
                <strong>Days to Find Next Expiration</strong>
                <br />
                <br />
                <input
                  type="text"
                  id="days"
                  name="days"
                  placeholder={data.days}
                  value={days}
                  style={inputStyle}
                  onChange={(e) => { setDays(e.target.value); uploadResult.current = ""; }}
                />
              </label>
              <label style={{ flex: "1" }}>
                <strong>Days Offset</strong>
                <br />
                <div>(if no options expiration on desired day):</div>
                <input
                  type="text"
                  name="daysOffset"
                  placeholder={data.daysOffset}
                  value={daysOffset}
                  style={inputStyle}
                  onChange={(e) => { setDaysOffset(e.target.value); uploadResult.current = ""; }}
                />
              </label>
            </div>

            {/* Minimum Depth and Exclude Leveraged */}
            <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
              <label style={{ flex: "1" }}>
                <strong>Minimum Depth In %</strong>
                <br />
                <input
                  type="text"
                  name="minDepth"
                  placeholder={data.minDepth}
                  value={minDepth}
                  style={inputStyle}
                  onChange={(e) => { setMinDepth(e.target.value); uploadResult.current = ""; }}
                />
              </label>
              <label style={{ flex: "1" }}>
                <strong>Exclude Leveraged ETFs</strong>
                <br />
                <select
                  name="excludeLeveragedEtfs"
                  placeholder={data.excludeLeveragedEtfs}
                  value={excludeLeveragedEtfs}
                  style={inputStyle}
                  onChange={(e) => { setExcludeLeveragedEtfs(e.target.value); uploadResult.current = ""; }}
                >
                  <option value="NO">NO</option>
                  <option value="YES">YES</option>
                </select>
              </label>
            </div>

            <br />
            {/* Grid Options Header */}
            <div style={{ backgroundColor: "blue", color: "white", padding: "10px", borderRadius: "5px", marginBottom: "10px" }}>
              <strong>Grid Options</strong>
            </div>
            <br />

            {/* Rank and Sort Profit */}
            <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
              <label style={{ flex: "1" }}>
                <strong>Rank</strong>
                <br />
                <select
                  name="rank"
                  placeholder={data.rank}
                  value={rank}
                  style={inputStyle}
                  onChange={(e) => { setRank(e.target.value); uploadResult.current = ""; }}
                >
                  <option value="ALL">ALL</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </label>
              <label style={{ flex: "1" }}>
                <strong>Profit Sort</strong>
                <br />
                <select
                  name="profitSort"
                  placeholder={data.profitSort}
                  value={profitSort}
                  style={inputStyle}
                  onChange={(e) => { setProfitSort(e.target.value); uploadResult.current = ""; }}
                >
                  <option value="">&nbsp;</option>
                  <option value="DESC">DESC</option>
                  <option value="ASC">ASC</option>
                </select>
              </label>
            </div>

            {/* Min Strike and Max Strike */}
            <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
              <label style={{ flex: "1" }}>
                <strong>Min Strike</strong>
                <br />
                <input
                  type="text"
                  name="strikeMin"
                  placeholder={data.strikeMin}
                  value={strikeMin}
                  style={inputStyle}
                  onChange={(e) => { setStrikeMin(e.target.value); uploadResult.current = ""; }}
                />
              </label>
              <label style={{ flex: "1" }}>
                <strong>Max Strike</strong>
                <br />
                <input
                  type="text"
                  name="strikeMax"
                  placeholder={data.strikeMax}
                  value={strikeMax}
                  style={inputStyle}
                  onChange={(e) => { setStrikeMax(e.target.value); uploadResult.current = ""; }}
                />
              </label>
            </div>
          </form>
          <br />
          <div style={{padding: "10px", borderRadius: "5px", marginBottom: "10px", marginTop: "100px" }}>
            <Button className="Button" onClick={handleSubmit} style={buttonStyle}>Submit</Button>
            <Button className="Button" onClick={defaultValues} style={buttonStyle}>Default</Button>
          </div>
          <br />
          <textarea hidden={hide} placeholder={error} readOnly></textarea>
        </>
      )}
    </div>
  );
};

export default Settings;
